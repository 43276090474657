import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "@store/store";
import { BrowserRouter } from "react-router-dom";
import { InterfaceContextAPI } from "@contexts/interfaceContext";

// fonts
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fonts/icomoon/icomoon.woff";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    {/* Redux Provider */}
    <Provider store={store}>
      {/* Persist Gate  */}
      <PersistGate persistor={persistor} loading={"loading persistor"}>
        {/* <Provider store={store}> */}
        {/* <BrowserRouter> */}
        <InterfaceContextAPI>
          <App />
        </InterfaceContextAPI>
        {/* </BrowserRouter> */}
        {/* </Provider> */}
      </PersistGate>
    </Provider>
  </>
);
