import React from "react";
import Panel from "@layout/Panel";
import Sidebar from "@layout/Sidebar";
import { getLoginReducer } from "@store/selectors";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import BottomMenu from "@layout/BottomMenu";
import useWindowSize from "@hooks/useWindowSize";

const MainLayout = ({ children }) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const { token, groups } = useSelector(getLoginReducer);

  // console.log("TOKEN :: ", token);

  // console.log("HERE :-: ", token);

  //  useEffect(() => {
  //    if (token) {
  //      if (groups?.length > 0) {
  //        router.push("/");
  //        return;
  //      }
  //      // else {
  //      //   router.push("/setup");
  //      //   return;
  //      // }
  //    }
  //    setIsAuth(true);
  //    // eslint-disable-next-line
  //  }, [token, groups]);

  return (
    <>
      {token && (
        <>
          <Sidebar />
          <div className="app_content">
            <Panel />
            {children}
          </div>
          {isMobile ? <BottomMenu /> : null}
        </>
      )}
      {!token && <Navigate to="/" />}
    </>
  );
};

export default MainLayout;
