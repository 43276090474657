import { configureStore } from "@reduxjs/toolkit";
import LayoutsReducer from "./features/layout";
import TodosReducer from "./features/todos";
import CardsReducer from "./features/cards";
import MessengerReducer from "./features/messenger";
import AuthReducer from "./features/auth";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";

import { combineReducers } from "redux";

const appReducer = combineReducers({
  // import other reducer
  layout: LayoutsReducer,
  todos: TodosReducer,
  cards: CardsReducer,
  messenger: MessengerReducer,
  loginReducer: AuthReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = {};
  }
  return appReducer(state, action);
  //     return {
  //       cartReducer,
  //    loginReducer
  //     }
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_REDUX_SECRET_KEY,
      onError: function (err) {
        console.log(err);
      },
    }),
  ],
};

// const store = configureStore({
//   reducer: {},
// });

// export default store;

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// const store = rootReducer

//store enhacers are remaining as of now

let persistor = persistStore(store);

export { store, persistor };
