export const getLoginReducer = (state) => state?.loginReducer || null;
export const getUserType = (state) => {
  const { groups } = state?.loginReducer;

  if (!groups) return null;
  return groups.includes("SuperAdmin")
    ? "SuperAdmin"
    : groups.includes("JobSeeker")
    ? "JobSeeker"
    : "NormalUser";
};
