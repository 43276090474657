// utils
import { lazy, Suspense } from "react";
import { PUBLIC_LAYOUT, MAIN_LAYOUT } from "@utils/routes";

// components
import ScrollProgress from "@ui/ScrollProgress";
import { MainLayout, PublicLayout } from "@layout/Layout";
import {
  Navigate,
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import WidgetsLoader from "@components/WidgetsLoader";

// hooks
import usePageIsOverflow from "@hooks/usePageIsOverflow";
import { useRef, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import { store } from "@store/store";
import { signIn } from "@store/features/auth";

const DashboardA = lazy(() => import("@pages/DashboardA"));
const AppLayout = () => {
  const appRef = useRef(null);
  const isOverflow = usePageIsOverflow();

  const dispatchUser = (currentAuthenticatedUser) => {
    console.log({ currentAuthenticatedUser });

    const { jwtToken, payload } =
      currentAuthenticatedUser?.signInUserSession?.accessToken;

    // const {
    //   loginReducer: { token, groups, userData },
    // } = store.getState();

    // if (token && groups && userData) return;

    const signInData = {
      token: jwtToken || null,
      groups: payload["cognito:groups"] || null,
      userData: currentAuthenticatedUser?.attributes || null,
    };
    store.dispatch(signIn(signInData));
    // message.success(Sign_In_Successfull);
    // router.push("/");
  };

  const fetchCurrentUserData = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    dispatchUser(currentUser);
  };

  useEffect(() => {
    if (appRef.current) {
      appRef.current.scrollTo(0, 0);
    }

    Hub.listen("auth", ({ payload }) => {
      const { event } = payload;
      console.log("EVENT :: ", event);
      switch (event) {
        case "signIn":
          dispatchUser(payload.data);
          break;
        case "autoSignIn":
          dispatchUser(payload.data);
          break;
        case "autoSignIn_failure":
          // router.push("/auth");
          // message.error(Something_went_wrong);
          break;
        case "signIn_failure":
          break;
        case "forgotPasswordSubmit":
          // router.push("/auth");
          // message.success(Sign_In_With_NewPassword);
          dispatchUser(payload.data);
          break;
        case "signUp":
          // router.push("/auth");
          // message.success(Sign_In_With_NewPassword);
          break;
        case "confirmSignUp":
          // router.push("/auth");
          // message.success(Sign_In_With_NewPassword);
          dispatchUser(payload.data);
          break;
        case "forgotPassword":
          // router.push("/auth");
          // message.success(Sign_In_With_NewPassword);
          break;
        case "signOut":
          // router.push("/auth");
          // message.success(Sign_In_With_NewPassword);
          localStorage?.clear();
          break;
        default:
          fetchCurrentUserData();
          break;
      }
    });
  }, []);

  const router = createBrowserRouter([
    {
      children: [
        // {
        //   path: "/",
        //   element: <DashboardA />,
        // },
        ...PUBLIC_LAYOUT.map(({ path, element }) => ({
          path,
          element: <PublicLayout children={element} />,
        })),
        { path: "/", element: <Navigate to="/dashboard" /> },
        ...MAIN_LAYOUT.map(({ path, element }) => ({
          path,
          element: <MainLayout children={element} />,
        })),
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);

  return (
    <div className="app" ref={appRef}>
      {isOverflow ? <ScrollProgress /> : null}

      {/* <Suspense fallback={<WidgetsLoader />}>
        <Routes>
          {PUBLIC_LAYOUT.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<PublicLayout children={element} />}
            />
          ))}
          <Route path="/" element={<Navigate to="/dashboard" />} />

          {MAIN_LAYOUT.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<MainLayout children={element} />}
            />
          ))}

          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Suspense> */}
      <Suspense fallback={<WidgetsLoader />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
};

export default AppLayout;
