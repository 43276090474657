import { lazy } from "react";

//pages
const Auth = lazy(() => import("@pages/Auth"));
const UploadTestResults = lazy(() => import("@pages/UploadTestResults"));
const Physical = lazy(() => import("@pages/PhysicalInfo"));
const Medication = lazy(() => import("@pages/MedicationInfo"));
const CrudData = lazy(() => import("@pages/LabInfo"));
const HelpPage = lazy(() => import("@pages/Help"));
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Advertisement = lazy(() => import("@pages/Advertisement"));
const Waitlist = lazy(() => import("@pages/Waitlist"));
const DashboardA = lazy(() => import("@pages/DashboardA"));
const DashboardB = lazy(() => import("@pages/DashboardB"));
const DashboardC = lazy(() => import("@pages/DashboardC"));
const DashboardD = lazy(() => import("@pages/DashboardD"));
const DashboardE = lazy(() => import("@pages/DashboardE"));
const DashboardF = lazy(() => import("@pages/DashboardF"));
const DashboardG = lazy(() => import("@pages/DashboardG"));
const DashboardH = lazy(() => import("@pages/DashboardH"));
const DashboardI = lazy(() => import("@pages/DashboardI"));
const DashboardJ = lazy(() => import("@pages/DashboardJ"));
const DashboardK = lazy(() => import("@pages/DashboardK"));
const DoctorAppointments = lazy(() => import("@pages/DoctorAppointments"));
const PatientAppointments = lazy(() => import("@pages/PatientAppointments"));
const Patients = lazy(() => import("@pages/Patients"));
const Tests = lazy(() => import("@pages/Tests"));
const Doctors = lazy(() => import("@pages/Doctors"));
const StaffList = lazy(() => import("@pages/Staff"));
const DoctorMessenger = lazy(() => import("@pages/DoctorMessenger"));
const PatientMessenger = lazy(() => import("@pages/PatientMessenger"));
const DoctorsReviews = lazy(() => import("@pages/DoctorsReviews"));
const PatientReviews = lazy(() => import("@pages/PatientReviews"));
const Finances = lazy(() => import("@pages/Finances"));
const Settings = lazy(() => import("@pages/Settings"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));

export const PUBLIC_LAYOUT = [
  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/",
    element: <Waitlist />,
  },
  {
    path: "/404",
    element: <PageNotFound />,
  },
  {
    path: "/dashboard/:id",
    element: <Dashboard />,
  },
];

export const MAIN_LAYOUT = [
  {
    path: "/upload-lab-results",
    element: <UploadTestResults />,
  },
  {
    path: "/my-lab-info",
    element: <CrudData />,
  },
  {
    path: "/medication-info",
    element: <Medication />,
  },
  {
    path: "/physical-info",
    element: <Physical />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/help",
    element: <HelpPage />,
  },
  {
    path: "/chat",
    element: <PatientMessenger type="chat" />,
  },
  {
    path: "/advertisement",
    element: <Advertisement />,
  },
  {
    path: "/dashboard_a",
    element: <DashboardA />,
  },
  {
    path: "/dashboard_b",
    element: <DashboardB />,
  },
  {
    path: "/dashboard_c",
    element: <DashboardC />,
  },
  {
    path: "/dashboard_d",
    element: <DashboardD />,
  },
  {
    path: "/dashboard_e",
    element: <DashboardE />,
  },
  {
    path: "/dashboard_f",
    element: <DashboardF />,
  },
  {
    path: "/dashboard_g",
    element: <DashboardG />,
  },
  {
    path: "/dashboard_h",
    element: <DashboardH />,
  },
  {
    path: "/dashboard_i",
    element: <DashboardI />,
  },
  {
    path: "/dashboard_j",
    element: <DashboardJ />,
  },
  {
    path: "/dashboard_k",
    element: <DashboardK />,
  },
  {
    path: "/doctor_appointments",
    element: <DoctorAppointments />,
  },
  {
    path: "/patient_appointments",
    element: <PatientAppointments />,
  },
  {
    path: "/patients",
    element: <Patients />,
  },
  {
    path: "/tests",
    element: <Tests />,
  },
  {
    path: "/doctors",
    element: <Doctors />,
  },
  {
    path: "/staff",
    element: <StaffList />,
  },
  {
    path: "/doctor_messenger",
    element: <DoctorMessenger />,
  },
  {
    path: "/patient_messenger",
    element: <PatientMessenger />,
  },
  {
    path: "/doctor_reviews",
    element: <DoctorsReviews />,
  },
  {
    path: "/patient_reviews",
    element: <PatientReviews />,
  },
  {
    path: "/finances",
    element: <Finances />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
];
