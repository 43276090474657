import { getLoginReducer } from "@store/selectors";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PublicLayout = ({ children }) => {
  const { token, groups } = useSelector(getLoginReducer);

  if (token) return <Navigate to="/dashboard" />;

  return children;
};

export default PublicLayout;
