// styled components
import { Menu, UserWrapper } from "../style";

// components
import Avatar from "@ui/Avatar";

// utils
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useState } from "react";

// assets
import doc1jpg from "@assets/avatars/doc1.jpg";
import doc1webp from "@assets/avatars/doc1.jpg?as=webp";

import { useDispatch, useSelector } from "react-redux";
import { getLoginReducer } from "@store/selectors";
import { Auth } from "aws-amplify";
import { signOut } from "@store/features/auth";

const CurrentUser = () => {
  const [open, setOpen] = useState(false);
  const handleClickAway = () => setOpen(false);
  const handleClick = () => setOpen(!open);
  const dispatch = useDispatch();

  const { userData } = useSelector(getLoginReducer);

  const src = {
    jpg: doc1jpg,
    webp: doc1webp,
  };

  const handleLogOut = async () =>
    await Auth.signOut({ global: true }).then(() => {
      dispatch(signOut());
    });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <UserWrapper>
        <Avatar
          //  avatar={src}
          alt="avatar"
          type={"text"}
        />
        <div className="info">
          {/* <span className="h3">Sallie McBride</span> */}
          <span className="h3">{userData?.email?.split("@")?.[0]}</span>
          {/* <span className="position">Surgeon</span> */}
          <Menu className={open ? "visible" : ""}>
            {/* <button>
                    <i className="icon icon-circle-user" /> Change user
                </button> */}
            <button onClick={handleLogOut}>
              <i className="icon icon-logout" /> Logout
            </button>
          </Menu>
        </div>
        <button
          className="trigger"
          onClick={handleClick}
          aria-label="Show menu"
        >
          <i className="icon icon-chevron-down" />
        </button>
      </UserWrapper>
    </ClickAwayListener>
  );
};

export default CurrentUser;
