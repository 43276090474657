import { createSlice } from "@reduxjs/toolkit";
import { auth } from "@db/auth";

export const Auth = createSlice({
  name: "auth",
  initialState: auth,
  reducers: {
    signIn: (state, { payload }) => {
      state.token = payload?.token;
      state.userData = payload?.userData;
      state.groups = payload?.groups;
    },
    signOut: (state) => {
      Object.assign(state, auth);
    },
  },
});

export const { signIn, signOut } = Auth.actions;
export default Auth.reducer;
