export const menuItems = [
  {
    icon: "DashboardIcon",
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    icon: "CloudUploadIcon",
    name: "Upload Lab Results",
    link: "/upload-lab-results",
  },
  {
    icon: "ScienceIcon",
    name: "Labs",
    link: "/my-lab-info",
  },
  {
    icon: "FitnessCenterIcon",
    name: "Physical",
    link: "/physical-info",
  },
  {
    icon: "MedicationIcon",
    name: "Meds",
    link: "/medication-info",
  },
  {
    icon: "HelpIcon",
    name: "Help",
    link: "/help",
  },
  {
    icon: "ChatIcon",
    name: "Chat",
    link: "/chat",
  },
  {
    icon: "CampaignIcon",
    name: "Advertisement",
    link: "/advertisement",
  },
];

export const menu = [
  // {
  // cat: "dashboard",
  // icon: "blocks",
  // links: [/
  // { name: "Dashboard A", link: "/dashboard_a" },
  // { name: "Dashboard B", link: '/dashboard_b' },
  // { name: "Dashboard C", link: '/dashboard_c' },
  // { name: "Dashboard D", link: '/dashboard_d' },
  // { name: "Dashboard E", link: '/dashboard_e' },
  // { name: "Dashboard F", link: '/dashboard_f' },
  // { name: "Dashboard G", link: '/dashboard_g' },
  // { name: "Dashboard H", link: '/dashboard_h' },
  // { name: "Dashboard I", link: '/dashboard_i' },
  // { name: "Dashboard J", link: '/dashboard_j' },
  // { name: "Dashboard K", link: '/dashboard_k' },
  // { name: "Page 404", link: '/404' },
  // ],
  // },
  // {
  //     cat: 'appointments',
  //     icon: 'calendar',
  //     links: [
  //         { name: "Patient Appointments", link: '/patient_appointments' },
  //         { name: "Doctor Appointments", link: '/doctor_appointments' }
  //     ]
  // },
  // {
  //     cat: 'patients',
  //     icon: 'users',
  //     links: [
  //         { name: "Patients", link: '/patients' },
  //         { name: "Tests", link: '/tests' }
  //     ]
  // },
  // {
  //     cat: 'doctors',
  //     icon: 'stethoscope',
  //     links: [
  //         { name: "Doctors", link: '/doctors' },
  //         { name: "Staff", link: '/staff' }
  //     ]
  // },
  {
    icon: "blocks",
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    icon: "cloudupload",
    name: "Upload Lab Results",
    link: "/upload-lab-results",
  },
  {
    icon: "cloudupload",
    name: "Labs",
    link: "/my-lab-info",
  },
  {
    icon: "cloudupload",
    name: "Physical",
    link: "/physical-info",
  },
  {
    icon: "cloudupload",
    name: "Meds",
    link: "/medication-info",
  },
  {
    icon: "cloudupload",
    name: "Help",
    link: "/help",
  },
  {
    icon: "cloudupload",
    name: "Chat",
    link: "/chat",
  },
  {
    icon: "cloudupload",
    name: "Advertisement",
    link: "/advertisement",
  },
  // {
  //   cat: "messages",
  //   icon: "comment",
  //   links: [
  //     { name: "Doctor Messenger", link: "/doctor_messenger" },
  //     { name: "Patient Messenger", link: "/patient_messenger" },
  //   ],
  // },
  // {
  //     cat: 'reviews',
  //     icon: 'star',
  //     links: [
  //         { name: "Doctor Reviews", link: '/doctor_reviews' },
  //         { name: "Patient Reviews", link: '/patient_reviews' }
  //     ]
  // },
  // {
  //     icon: 'wallet',
  //     name: 'Finances',
  //     link: '/finances'
  // },
  // {
  //   icon: "settings",
  //   name: "Settings",
  //   link: "/settings",
  // },
];
