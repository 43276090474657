// styles
import GlobalStyles from "@styles/global";
import "@styles/custom.css";
import "react-grid-layout/css/styles.css";
import "./fonts/icomoon/style.css";
import "swiper/css";
import "swiper/css/effect-fade";

// components
import AppLayout from "./AppLayout";
import { SnackbarProvider } from "notistack";

// utils
import { ThemeProvider, StyleSheetManager } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { preventDefault } from "@utils/helpers";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// contexts
import { SidebarContextAPI } from "@contexts/sidebarContext";

// hooks
import { useEffect } from "react";
import { useInterfaceContext } from "@contexts/interfaceContext";
import { useDispatch } from "react-redux";

// actions
import { saveToLocalStorage } from "@store/features/layout";
import { Amplify, Auth } from "aws-amplify";

import config from "./aws-exports";
import { signOut } from "@store/features/auth";

import { SWRConfig } from "swr";
import ReactGA from "react-ga4";

Amplify.configure({
  ...config,
  // ssr: true,
  API: {
    graphql_headers: async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    }),
  },
});

const App = () => {
  const TRACKING_ID = "G-PH1RMWJ93T";

  const dispatch = useDispatch();

  const page = document.documentElement;
  const { isDarkMode, isContrastMode, direction } = useInterfaceContext();
  const theme = createTheme({
    direction: direction,
  });
  const cacheRtl = createCache({
    key: "css-rtl",
    stylisPlugins: [rtlPlugin],
  });

  useDispatch()(saveToLocalStorage());

  useEffect(() => {
    page.setAttribute("dir", direction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  const handleSignOut = async () => {
    return await Auth.signOut({ global: true }).then(() => {
      dispatch(signOut());
    });
  };

  const checkAwsToken = () => {
    const userClientId = config.aws_user_pools_web_client_id;
    const lastAuthUserKey = `CognitoIdentityServiceProvider.${userClientId}.LastAuthUser`;
    const lastAuthUser = localStorage.getItem(lastAuthUserKey);
    if (!lastAuthUser) {
      handleSignOut();
    }
  };

  useEffect(() => {
    isContrastMode && page.classList.add("contrast");
    preventDefault();
    checkAwsToken();
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "dashboard",
      page: "/dashboard",
      title: "Dashboard Page",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const swrConfig = {
    revalidateOnFocus: false,
    refreshInterval: 60000,
    // Other SWR configuration options
  };

  return (
    <SWRConfig value={swrConfig}>
      <CacheProvider value={cacheRtl}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={{ theme: isDarkMode ? "dark" : "light" }}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "top",
                horizontal: direction === "ltr" ? "right" : "left",
              }}
              autoHideDuration={3000}
            >
              <SidebarContextAPI>
                <GlobalStyles />
                <StyleSheetManager
                  stylisPlugins={direction === "rtl" ? [rtlPlugin] : []}
                >
                  <AppLayout />
                </StyleSheetManager>
              </SidebarContextAPI>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </CacheProvider>
    </SWRConfig>
  );
};

export default App;
